export const NoImageAvailable = 'https://firebasestorage.googleapis.com/v0/b/tacomonster-a73fa.appspot.com/o/Products%2Fcoming-soon-photo.jpg?alt=media&token=c917b0d8-4f97-4824-9b07-ed51759c8b31'

export const MenuConfig = [
    {
        category: "Tacos",
        items: [
            {
                name: "Soft Taco",
                ingredients: 'protein, Lettuce, Cheese',
                description: "Snack sized wrap filled with protein, lettuce and cheese",
                imageUrl: "https://firebasestorage.googleapis.com/v0/b/tacomonster-a73fa.appspot.com/o/Products%2Fsoft-taco.jpg?alt=media&token=3f177c9b-8158-4a13-8508-bdf800ccd9a7",
                extras: [
                    {
                        type: 'Sour Cream',
                        price: 0.40
                    },
                    {
                        type: 'Crispy Onions',
                        price: 0.40
                    },
                    {
                        type: 'Double protein',
                        price: 1.00
                    }
                ],
                protein: [
                    {
                        type: 'Chicken',
                        price: null
                    },
                    {
                        type: 'Beef',
                        price: null
                    },
                    {
                        type: 'Spicy Bean',
                        price: null
                    },
                ],
                price: 2.15,
            },
            {
                name: "Crunchy Taco",
                ingredients: 'protein, Lettuce, Cheese',
                description: "Snack sized wrap filled with protein, lettuce and cheese",
                imageUrl: "https://firebasestorage.googleapis.com/v0/b/tacomonster-a73fa.appspot.com/o/Products%2Fcrunchy-taco-beef.jpg?alt=media&token=26456ca4-7a2c-4da2-b182-8c8d2b9bb1c6",
                extras: [
                    {
                        type: 'Sour Cream',
                        price: 0.40
                    },
                    {
                        type: 'Crispy Onions',
                        price: 0.40
                    },
                    {
                        type: 'Double protein',
                        price: 1.00
                    }
                ],
                protein: [
                    {
                        type: 'Chicken',
                        price: null
                    },
                    {
                        type: 'Beef'
                    },
                    {
                        type: 'Spicy Bean',
                        price: null
                    },
                ],
                price: 2.15,
            },
            // {
            //     name: "Crunchy Taco Monster",
            //     ingredients: 'protein, Lettuce, Cheese',
            //     description: "Extra large taco with protein, lettuce and cheese",
            //     imageUrl: "https://firebasestorage.googleapis.com/v0/b/tacomonster-a73fa.appspot.com/o/Products%2Fcrunchy-taco-beef.jpg?alt=media&token=26456ca4-7a2c-4da2-b182-8c8d2b9bb1c6",
            //     extras: [
            //         {
            //             type: 'Sour Cream',
            //             price: 0.50
            //         },
            //         {
            //             type: 'Crispy Onions',
            //             price: 0.50
            //         }
            //     ],
            //     protein: [
            //         {
            //             type: 'chicken',
        //    price: null
            //         },
            //         {
            //             type: 'beef'
            //         },
            //         {
            //             type: 'spicy bean',
            // price: null
            //         },
            //     ],
            //     price: 7.99,
            // },
            // {
            //     name: "Soft Taco Monster",
            //     ingredients: 'protein, Lettuce, Cheese',
            //     description: "Extra large soft taco with protein, lettuce and cheese",
            //     imageUrl: "https://firebasestorage.googleapis.com/v0/b/tacomonster-a73fa.appspot.com/o/Products%2Fsoft-taco.jpg?alt=media&token=3f177c9b-8158-4a13-8508-bdf800ccd9a7",
            //     extras: [
            //         {
            //             type: 'Sour Cream',
            //             price: 0.50
            //         },
            //         {
            //             type: 'Crispy Onions',
            //             price: 0.50
            //         }
            //     ],
            //     protein: [
            //         {
            //             type: 'chicken',
           // price: null
            //         },
            //         {
            //             type: 'beef'
            //         },
            //         {
            //             type: 'spicy bean',
            // price: null
            //         },
            //     ],
            //     price: 7.99,
            // }
        ],
    },
    {
        category: "Burritos",
        items: [
            {
                name: "Burrito",
                ingredients: 'protein, Lettuce, Cheese',
                description: "Full sized wrap filled with protein, lettuce and cheese",
                imageUrl: "https://firebasestorage.googleapis.com/v0/b/tacomonster-a73fa.appspot.com/o/Products%2Fburrito.jpg?alt=media&token=61fc95a3-19f3-45b3-8697-399d9c99678c",
                extras: [
                    {
                        type: 'Double Rice',
                        price: 1.00
                    },
                    {
                        type: 'Double Cheese',
                        price: 1.00
                    },
                    {
                        type: 'Double protein',
                        price: 1.00
                    }
                ],
                protein: [
                    {
                        type: 'Chicken',
                        price: null
                    },
                    {
                        type: 'Beef',
                        price: null
                    },
                    {
                        type: 'Spicy Bean',
                        price: null
                    },
                ],
                price: 5.69,
            },
            {
                name: "Burrito Monster",
                ingredients: 'protein, Lettuce, Cheese',
                description: "Extra large burrito filled with protein, lettuce and cheese",
                imageUrl: "https://firebasestorage.googleapis.com/v0/b/tacomonster-a73fa.appspot.com/o/Products%2Fburrito.jpg?alt=media&token=61fc95a3-19f3-45b3-8697-399d9c99678c",
                extras: [
                    {
                        type: 'Double Rice',
                        price: 1.00
                    },
                    {
                        type: 'Double Cheese',
                        price: 1.00
                    },
                ],
                protein: [
                    {
                        type: 'Chicken',
                        price: null
                    },
                    {
                        type: 'Beef',
                        price: null
                    },
                    {
                        type: 'Spicy Bean',
                        price: null
                    },
                ],
                secondProtein: [
                    {
                        type: 'Chicken',
                        price: null
                    },
                    {
                        type: 'Beef',
                        price: null
                    },
                    {
                        type: 'Spicy Bean',
                        price: null
                    },
                ],
                drink: [
                    {
                        type: 'Pepsi Max 330ml',
                        price: 1.65
                    },
                    {
                        type: 'Pepsi Max Cherry 330ml',
                        price: 1.65
                    },
                    {
                        type: 'Tango Apple 330ml',
                        price: 1.65
                    },
                    {
                        type: 'Original Coca Cola 330ml',
                        price: 1.65
                    }
                ],
                price: 12.50
                ,
            },
            {
                name: "Create your own burrito",
                ingredients: 'Choose your own ingredients',
                description: "Customize your burrito with your favorite ingredients",
                imageUrl: "https://firebasestorage.googleapis.com/v0/b/tacomonster-a73fa.appspot.com/o/Products%2Fburrito.jpg?alt=media&token=61fc95a3-19f3-45b3-8697-399d9c99678c",
                extras: [
                    {
                        type: 'Sour Cream',
                        price: 0.50
                    },
                    {
                        type: 'Crispy Onions',
                        price: 0.30
                    },
                    {
                        type: 'Diced Tomatoes',
                        price: 0.30
                    },
                    {
                        type: 'Diced Red Onions',
                        price: 0.30
                    },
                    {
                        type: 'Jalapenos',
                        price: 0.30
                    },
                    {
                        type: 'Mayo',
                        price: 0.30
                    },
                    {
                        type: 'Garlic Mayo',
                        price: 0.30
                    },
                    {
                        type: 'TM Special Chipotle Sauce (medium heat)',
                        price: 0.50
                    },
                    {
                        type: 'Grated Cheddar Cheese',
                        price: 0.75
                    },
                    {
                        type: 'Melted Cheese',
                        price: 0.75
                    },
                    {
                        type: 'Seasoned Fries',
                        price: 1.00
                    },
                    {
                        type: 'TM Special Salsa',
                        price: 0.75
                    },
                    {
                        type: 'Extra Beef',
                        price: 2.00
                    },
                    {
                        type: 'Extra Chicken',
                        price: 2.00
                    },
                    {
                        type: 'Extra Spicy Bean',
                        price: 2.00
                    }
              
      

                ],
                protein: [
                    {
                        type: 'Chicken',
                        price: null
                    },
                    {
                        type: 'Beef',
                        price: null
                    },
                    {
                        type: 'Spicy Bean',
                        price: null,
                      
                    },
                ],
                drink: [
                    {
                        type: 'Pepsi Max 330ml',
                        price: 1.65
                    },
                    {
                        type: 'Pepsi Max Cherry 330ml',
                        price: 1.65
                    },
                    {
                        type: 'Tango Apple 330ml',
                        price: 1.65
                    },
                    {
                        type: 'Original Coca Cola 330ml',
                        price: 1.65
                    }
                ],
                price: 4.69,
            }
        ],
    },
    {
        category: "Fries",
        items: [
            {
                name: "Monster Fries",
                ingredients: 'protein, Lettuce, Cheese',
                description: "Fries with protein, lettuce and cheese",
                imageUrl: "https://firebasestorage.googleapis.com/v0/b/tacomonster-a73fa.appspot.com/o/Products%2Fmonster-fries-white-bg.jpg?alt=media&token=cf181244-877c-4a6c-b8c2-9ccb1e5cd10c",
                extras: [
                    {
                        type: 'Double Protein',
                        price: 2.00
                    },
                    {
                        type: 'Crispy Onions',
                        price: 0.50
                    },
                    {
                        type: 'TM Special Chipotle Sauce (medium heat)',
                        price: 0.50
                    },
                    {
                        type: 'TM Special Salsa',
                        price: 0.75
                    }
                ],
                protein: [
                    {
                        type: 'Chicken',
                        price: null
                    },
                    {
                        type: 'Beef',
                        price: null
                    },
                    {
                        type: 'Spicy Bean',
                        price: null
                    },
                ],
                price: 6.29,
            },
            {
                name: "Cheesy Fries",
                ingredients: 'Fries Cheese',
                description: "Fries with cheese",
                imageUrl: "https://firebasestorage.googleapis.com/v0/b/tacomonster-a73fa.appspot.com/o/Products%2Fcheesy-fries-white-bg.jpg?alt=media&token=f0e37da4-2c07-48fc-bf88-7757e7270ea3",
                extras: [
                    {
                        type: 'Crispy Onions',
                        price: 0.50
                    }
                ],
                price: 4.19,
            },
            {
                name: "Seasoned Fries",
                ingredients: 'Seasoned Fries',
                description: "Fries with special seasoning",
                imageUrl: "https://firebasestorage.googleapis.com/v0/b/tacomonster-a73fa.appspot.com/o/Products%2Fseasoned-fries-white-bg.jpg?alt=media&token=dc819015-7967-46b5-8bca-242012e54793",
                extras: [

                    {
                        type: 'Crispy Onions',
                        price: 0.50
                    }
                ],
                price: 2.35,
            },
            // {
            //     name: "Nacho Monster",
            //     ingredients: 'Nacho Chips, protein, Lettuce, Cheese',
            //     description: "Nacho chips with protein, lettuce and cheese",
            //     imageUrl: "11062b_4074ac690fe04eef94fa7abfff3e0a51~mv2.jpg",
            //     extras: [
            //         {
            //             type: 'Sour Cream',
            //             price: 0.50
            //         },
            //         {
            //             type: 'Crispy Onions',
            //             price: 0.50
            //         }
            //     ],
            //     protein: [
            //         {
            //             type: 'chicken',
            // price: null
            //         },
            //         {
            //             type: 'beef'
            //         },
            //         {
            //             type: 'spicy bean',
            // price: null
            //         },
            //     ],
            //     price: 7.99,
            // },
            // {
            //     name: "Cheesy Nachos",
            //     ingredients: 'Nacho Chips, Cheese',
            //     description: "Nacho chips with cheese",
            //     imageUrl: "11062b_d57e81fe12054313a65ea779e858aa55~mv2_d_4608_3072_s_4_2.jpg",
            //     extras: [
            //         {
            //             type: 'Sour Cream',
            //             price: 0.50
            //         },
            //         {
            //             type: 'Crispy Onions',
            //             price: 0.50
            //         }
            //     ],
            //     price: 6.99,
            // }
        ],
    },
    {
        category: "Grillers",
        items: [
            {
                name: "Chicken Drizzle Griller",
                ingredients: 'Chicken, Cheese, Drizzle Sauce',
                description: "Home made pulled chicken with cheese and special drizzle sauce",
                imageUrl: "https://firebasestorage.googleapis.com/v0/b/tacomonster-a73fa.appspot.com/o/Products%2Fchicken-drizzle.jpg?alt=media&token=2cbdd773-cc3f-440d-b73a-a38f423667ca",
                extras: [
                    {
                        type: 'Cheese',
                        price: 1.00
                    },
                    {
                        type: 'Crispy Onions',
                        price: 0.50
                    }
                ],
                price: 3.09,
            },
            {
                name: "Beany Cheese Griller",
                ingredients: 'Beans, Cheese',
                description: "Grilled beans with cheese",
                imageUrl: "https://firebasestorage.googleapis.com/v0/b/tacomonster-a73fa.appspot.com/o/Products%2Fbeany-cheese.jpg?alt=media&token=6ae009a0-bae5-4a27-9402-eccf7f06ea57",
                extras: [
                    {
                        type: 'Double Cheese',
                        price: 1.00
                    },
                    {
                        type: 'Crispy Onions',
                        price: 0.50
                    }
                ],
                price: 3.09,
            },
            {
                name: "Beefy Cheese Griller",
                ingredients: 'Beef, Cheese',
                description: "Grilled beef with cheese",
                imageUrl: "https://firebasestorage.googleapis.com/v0/b/tacomonster-a73fa.appspot.com/o/Products%2Fbeefy-cheese.jpg?alt=media&token=7e87c698-4c68-4314-8d47-80ece3c505e8",
                extras: [
                    {
                        type: 'Double Cheese',
                        price: 1.00
                    },
                    {
                        type: 'Crispy Onions',
                        price: 0.50
                    }
                ],
                price: 3.39,
            }
        ],
    },
    {
        category: "Drinks",
        items: [
            {
                name: "Fanta",
                ingredients: 'Fanta',
                description: "Refreshing Fanta drink",
                imageUrl: "https://firebasestorage.googleapis.com/v0/b/tacomonster-a73fa.appspot.com/o/Products%2Ffanta-fruit-twist.jpg?alt=media&token=dd822c55-74d6-4215-86d0-d735075ee4f8",
                price: 2.99,
                extras: null,
            },
            {
                name: "Pepsi Max Cherry",
                ingredients: 'Pepsi Max Cherry',
                description: "Refreshing Cherry drink",
                imageUrl: "https://firebasestorage.googleapis.com/v0/b/tacomonster-a73fa.appspot.com/o/Products%2Fpepsi-max-cherry.jpg?alt=media&token=fac58f45-a7e9-48a1-b7bc-c3edccafe4d5",
                price: 2.99,
                extras: null,
            },
            {
                name: "Pepsi Max",
                ingredients: 'Pepsi Max',
                description: "Refreshing Pepsi drink",
                imageUrl: "https://firebasestorage.googleapis.com/v0/b/tacomonster-a73fa.appspot.com/o/Products%2Fpepsi-max.jpg?alt=media&token=2961640e-d4fd-4842-9c76-0b632333e320",
                price: 2.99,
                extras: null,
            }
        ],
    },
    {
        category: "Desserts",
        items: [
            {
                name: "6 Churros with 2 sauces",
                ingredients: 'Churros, Dulce de Leche',
                description: "Crunchy dough swirls covered with cinnamon sugar, served with Dulce de Leche sauce. Contains milk 'including lactose'",
                imageUrl: "https://firebasestorage.googleapis.com/v0/b/tacomonster-a73fa.appspot.com/o/Products%2F2churrosdulce.jpg?alt=media&token=b471a741-1193-4968-879c-1d42116e6fa1",
                price: 6.45,
                sauce: [
                    {
                        type: 'Dulce de Leche',
                        price: null
                    },
                    {
                        type: 'Hershies Chocolate',
                        price: null
                    }
                ],
                secondSauce: [
                    {
                        type: 'Dulce de Leche',
                        price: null
                    },
                    {
                        type: 'Hershies Chocolate',
                        price: null
                    }
                ],
            },
            {
                name: "Churros with Dulce de Leche",
                ingredients: 'Churros',
                description: "Two Delicious churros  covered with cinnamon sugar, served with Dulce de Leche sauce. Contains milk 'including lactose'.",
                imageUrl: "https://firebasestorage.googleapis.com/v0/b/tacomonster-a73fa.appspot.com/o/Products%2F2churros.jpg?alt=media&token=50c8f753-f475-42fb-bbde-7060ff1debf7",
                price: 3.14,
                extras: null,
            },
            
        ],
    },
    
    {
        category: "Combos",
        items: [
            {
                name: "Griller Combo",
                ingredients: '',
                description: "Three flavour packed Grillers of choice with a portion of seasoned fries.",
                imageUrl: "https://firebasestorage.googleapis.com/v0/b/tacomonster-a73fa.appspot.com/o/Products%2FScreenshot%202024-02-17%20at%2000.28.39.png?alt=media&token=81a1687f-e6f5-4471-b2f6-00b72d5eb9a9",
                price: 10.55,
                extras: [
                    {
                        type: 'Go Large',
                        price: 1.50
                    },
                  
                ],
                protein: [
                    {
                        type: 'Chicken',
                        price: null
                    },
                    {
                        type: 'Beef',
                        price: null
                    },
                    {
                        type: 'Spicy Bean',
                        price: null
                    },
                ],
                secondProtein: [
                    {
                        type: 'Chicken',
                        price: null
                    },
                    {
                        type: 'Beef',
                        price: null
                    },
                    {
                        type: 'Spicy Bean',
                        price: null
                    },
                ],
                thirdProtein: [
                    {
                        type: 'Chicken',
                        price: null
                    },
                    {
                        type: 'Beef',
                        price: null
                    },
                    {
                        type: 'Spicy Bean',
                        price: null
                    },
                ],
             
            }
        ],
    },
    {
        category: "Kids",
        items: [
            {
                name: "Chicken Nuggets",
                ingredients: 'Chicken breast nuggets',
                description: "5 tasty nuggets made with chicken breast, cooked golden brown and served with a pot of Ketchup.",
                imageUrl: "https://s3.dualstack.ap-south-1.amazonaws.com/shopnowchat.com/Medium/MPRNEclfVnmUR_ChickenNuggets(5piece).jpg",
                price: 2.65,
                extras: [
                    {
                        type: 'Fries (Unseasoned)',
                        price: 1.50
                    },
                    {
                        type: 'Double Nuggets (10)',
                        price: 2.50
                    }
                ],
             
            },
        ],
    }
]
