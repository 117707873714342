import React from 'react';
import Menu from '../components/Menu/Menu';

const Home = () => {
  return (

<div>
<Menu/>
</div>
 
  )
};

export default Home;